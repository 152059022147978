import { createSlice } from '@reduxjs/toolkit';

interface ScriptState {
  script: object;
}

const initialState: ScriptState = {
  script: {},
};

const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    saveScript: (state, action: any) => {
      state.script = action;
    },
  },
});

export const { saveScript } = scriptSlice.actions;
export default scriptSlice.reducer;
