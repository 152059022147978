// import React, { useState } from 'react';
import CreateNewModal from '../shared/createNewModal';
import { useNavigate } from 'react-router-dom';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
const Header = (props:any) => {
  console.log("is edit",props.isEdit)
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    return navigate(path);
  };

  return (
    <>
      <header className="bg-white border-y-2">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a  onClick={() => {handleNavigation('/dashboard');}} className="-m-1.5 p-1.5" style={{cursor:'pointer'}}>
              <span className="sr-only">Vid Snap</span>
              <img className="h-8 w-auto" src="/images/logo.png" alt="" />
            </a>
          </div>
          {props.isEdit === false ?
           <div className="inline-flex">
            <div className="createNewHeader">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 gap-y-px"
                style={{ backgroundColor: '#30A6DF', color: '#ffff' }}
                onClick={() => {
                  handleNavigation('/createNewProject');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Create New Project
              </button>
            </div>

            <div className="">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 gap-y-px"
                style={{ backgroundColor: '#30A6DF', color: '#ffff' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
                User Admin
              </button>
            </div>
          </div>
          :
          <div className="inline-flex">
            <div className="createNewHeader">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 gap-2 gap-y-px"
                style={{ backgroundColor: '#30A6DF', color: '#ffff' }}
                onClick={() => {
                  handleNavigation('/createNewProject');
                }}
              >
              <FiSend/>
                Send Proof
              </button>
            </div>

            <div className="">
              <button
                type="button"
                className="-m-2.5 text-white bg-green-500 inline-flex items-center justify-center rounded-md p-2.5 gap-2 gap-y-px"
              >
               <IoCloudDownloadOutline/>
                Export
              </button>
            </div>
          </div>
}
        </nav>
      </header>
    </>
  );
};

export default Header;
