import React from 'react';

import { Player } from '@remotion/player';
import { MyComposition } from '../remotion/Composition';

const VideoPlayer = () => {
  return (
    <div className='mt-1'>
      <Player
        component={MyComposition}
        inputProps={{ text: 'World' }}
        durationInFrames={120}
        compositionWidth={1920}
        compositionHeight={1080}
        fps={30}
        style={{
          width: 880,
          // height: 720,
        }}
        controls
      />

      {/*  */}
    </div>
  );
};

export default VideoPlayer;
