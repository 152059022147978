import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
const AcceptModal = (props:any) => {
  const getScript = useSelector((state: any) => state.script.script);
  const [script, setScript] = useState<any>([]);
  const [showModalScript, setShowModalScript] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const navigate = useNavigate();

  const handleGenerate = () => {
    setShowModalScript(false);
    const scriptContent =props.script;
    navigate("/editor", { state: { scriptContent } });
  };
  const handleClose = () => {
    navigate("/dashboard");
  };

  // console.log("script",props.script);


  // useEffect(() => {
  //   //
  //   if (getScript.payload && getScript.payload.length > 0) {
  //     setShowModalScript(true);
  //     setScript(getScript.payload);
  //     console.log("scriptPayload", getScript.payload);
  //     console.log("script", script);
  //   } else {
  //     navigate("/createNewProject");
  //     return;
  //   }
  // }, [getScript.payload, script]);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative ">
          {/*content*/}
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none "
            style={{ width: "800px" }}
          >
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
              <h6 className="text-2xl text-black">New Project</h6>
              <div className="flex items-center justify-end  border-blueGray-200 rounded-b">
                <button
                  className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  X
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {/* {renderScript()} */}
              <h2 className="font-bold mb-2">Script: 1</h2>
              <div>
                <h1>{props.script}</h1>
              </div>
            </div>
            {/*footer*/}

            <div className="flow-root px-3 py-2">
              <button
                type="button"
                className="inline-item float-right px-3 py-2 mx-0.5 text-white bg-sky-400 text-sm font-medium focus:ring-4 focus:ring-blue-200"
                onClick={handleGenerate}
              >
                Generate Video
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AcceptModal;
