"use client";

import { fabric } from "fabric";
import React, { useEffect, useState } from "react";
import { StoreContext } from "../store/StoreContext";
import { observer } from "mobx-react";
import { Resources } from "./Resources";
import { ElementsPanel } from "./panels/ElementsPanel";
import { Menu } from "./Menu";
import { TimeLine } from "./TimeLine";
import { Store } from "../store/Store";
import "../utils/fabric-utils";

export const EditorWithStore = () => {
  const [store] = useState(new Store());
  return (
    <StoreContext.Provider value={store}>
      <Editor></Editor>
    </StoreContext.Provider>
  );
};

export const Editor = observer((props: any) => {
  const store = React.useContext(StoreContext);
  console.log("store", store);
  const scriptContent = props.script;
  useEffect(() => {
    const canvasElement = document.getElementById(
      "canvas"
    ) as HTMLCanvasElement;
    if (!canvasElement) return;

    const canvas = new fabric.Canvas(canvasElement, {
      height: 500,
      width: 800,
      backgroundColor: "#ededed",
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "#00a0f5";
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerStrokeColor = "#0063d8";
    fabric.Object.prototype.cornerSize = 10;

    // Canvas mouse down without target should deselect active object
    canvas.on("mouse:down", function (e) {
      if (!e.target) {
        store.setSelectedElement(null);
      }
    });

    store.setCanvas(canvas);

    store.addText({
      text: scriptContent,
      fontSize: 24,
      fontWeight: 10,
    });

    let animationFrameId: number;

    const renderLoop = () => {
      if (canvas && canvasElement.isConnected) {
        // Ensure canvas element is connected to the DOM
        canvas.renderAll();
        animationFrameId = fabric.util.requestAnimFrame(renderLoop);
      }
    };

    animationFrameId = fabric.util.requestAnimFrame(renderLoop);

    return () => {
      fabric.util.cancelAnimFrame(animationFrameId); // Cancel the animation frame
      canvas.dispose();
    };
  }, [store, scriptContent]);
  return (
    <div className="grid grid-rows-[500px_1fr_20px] grid-cols-[72px_300px_1fr_250px] h-[100svh] overflow-hidden">
      <div className="tile row-span-2 flex flex-col">
        <Menu />
      </div>
      <div className="row-span-2 flex flex-col overflow-scroll">
        <Resources script={scriptContent} />
      </div>
      <div
        id="grid-canvas-container"
        className="col-start-3 bg-slate-100 flex justify-center items-center"
      >
        <canvas id="canvas" className="h-[500px] w-[800px] row" />
      </div>
      <div className="col-start-4 row-start-1 ">
        <ElementsPanel />
      </div>
      <div className="col-start-3 row-start-2 col-span-2 relative px-[10px] py-[4px] overflow-scroll">
        <TimeLine />
      </div>
    </div>
  );
});
