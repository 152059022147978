import Header from '../Components/Header';

const Dashboard = () => {
  const menuItem = [
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/1.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/2.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/3.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/4.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/5.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/6.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/7.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/8.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/9.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/10.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/11.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/12.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/13.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/14.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/15.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/16.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/17.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/18.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/19.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/20.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/21.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
  ];
  return (
    <>
      <Header isEdit={false}/>

      <div>
        <p className="text-center text-5xl mt-4 break-normal">
          Create beautiful videos for your social <br /> media campaign
        </p>
        <p className="text-center mt-2">
          Pick a template and create a design in just a few clicks!
        </p>
      </div>

      <div className="max-w-[1640px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6">
        {/* Card */}

        {menuItem.map(({ text, subtitle, image, ratio, id }, index) => {
          return (
            <div key={index} className="rounded-xl relative">
              {/* Overlay */}
              <div className="absolute w-full h-full bg-black/50 rounded-xl text-white">
                <p className="font-bold text-2xl px-2 pt-4">{text} </p>
                <p className="px-2">{subtitle}</p>
                <p className="px-2">{ratio}</p>
                <p className="px-2">{id}</p>
              </div>
              <img
                className="max-h-[160px]  md:max-h-[200px] w-full object-cover rounded-xl"
                src={image}
                alt="/"
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Dashboard;
