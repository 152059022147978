import { Route, Routes } from 'react-router-dom';
import './App.css';

//Library import
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Pages
import Login from './Pages/login';
import Dashboard from './Pages/dashboard';
import Video from './Pages/VideoPlayer';
import CreateNewProject from './Pages/createNewProject';
import Script from './Pages/script';
import Accept from './Pages/accept';
import EditorPage from './Pages/editor';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path="/video" Component={Video} />
        <Route path="/createNewProject" Component={CreateNewProject} />
        <Route path="/script" Component={Script} />
        <Route path="/accept" Component={Accept} />
        <Route path="/editor" Component={EditorPage} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
