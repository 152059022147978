import Header from '../Components/Header';
import ScriptModal from '../shared/scriptModal';

const Script = () => {
  return (
    <>
      <Header isEdit={false}/>
      <ScriptModal />
    </>
  );
};

export default Script;
