import React from 'react'
import Header from '../Components/Header'
import {Sidebar} from '../Components/Sidebar'
import './editor.css'
import VideoPlayer from './VideoPlayer'
import { Editor } from '../Components/Editor'
import { EditorStatic } from '../Components/EditorStatic'
import { useLocation } from 'react-router-dom'
const EditorPage = () => {
  const location = useLocation();
  const { scriptContent } = location.state || {};
  return (
    <>
    <Header isEdit={true}/>
    <Editor script={scriptContent}/>
    {/* <Editor/> */}
    {/* <div className="app">
      <Sidebar />
      <div className="content">
        <Video/>
      </div>
    </div> */}
    </>
  )
}

export default EditorPage