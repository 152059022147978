import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import { IoCloudDownloadOutline } from "react-icons/io5";
const ScriptModal = () => {
  const getScript = useSelector((state: any) => state.script.script);
  const [script, setScript] = useState<any>([]);
  const [showModalScript, setShowModalScript] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const navigate = useNavigate();

  const handleAccept = () => {
    const scriptContent = script[contentIndex]?.message?.content;
    setShowModalScript(false);
    navigate("/accept", { state: { scriptContent } });
  };
  const handleReject = () => {
    setShowModalScript(false);
    navigate("/createNewProject");
  };
  const handleClose = () => {
    navigate("/createNewProject");
  };

  const handleDownload = () => {
    const content = script[contentIndex]?.message?.content;
    if (content) {
      const blob = new Blob([content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'script.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      console.error('No content available to download');
    }
  };

  useEffect(() => {
    //
    if (getScript.payload && getScript.payload.length > 0) {
      setShowModalScript(true);
      setScript(getScript.payload);
      console.log("scriptPayload", getScript.payload);
      console.log("script", script);
    } else {
      navigate("/createNewProject");
      return;
    }
  }, [getScript.payload, script]);

  const handleForward = () => {
    if (contentIndex + 1 < script.length) setContentIndex(contentIndex + 1);
  };
  const handleBack = () => {
    if (contentIndex > 0) setContentIndex(contentIndex - 1);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative ">
          {/*content*/}
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none "
            style={{ width: "800px" }}
          >
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
              <h6 className="text-2xl text-black">New Project</h6>
              <div className="flex items-center justify-end  border-blueGray-200 rounded-b">
                <button
                  className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  X
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {/* {renderScript()} */}
              <h2 className="font-bold mb-2">Script: {contentIndex + 1}</h2>
              <div>
                <h1>{script[contentIndex]?.message?.content}</h1>
              </div>

              <div className="flex gap-5 mt-5 ">
                <div onClick={handleBack}>
                  <p className="border-2 px-5 py-2 text-2xl cursor-pointer hover:bg-sky-400">
                  <IoIosArrowBack />
                  </p>
                </div>
                <div onClick={handleForward}>
                  <p className="border-2 px-5 py-2 text-2xl cursor-pointer hover:bg-sky-400">
                    <IoIosArrowForward/>
                  </p>
                </div>
              </div>
            </div>
            {/*footer*/}

            <div className="flow-root px-3 py-2">
              <button
                type="button"
                className="inline-item float-right px-3 py-2 mx-0.5 text-white bg-sky-400 text-sm font-medium focus:ring-4 focus:ring-blue-200"
                onClick={handleAccept}
              >
                Accept
              </button>
              <button
                type="button"
                className="inline-item float-right px-3 py-2 text-white bg-sky-400 text-sm font-medium focus:ring-4 focus:ring-blue-200"
                onClick={handleReject}
              >
                Reject
              </button>
              <p
                className="cursor-pointer inline-item float-right px-3 py-2 text-xl mx-0.5 text-white bg-sky-400 focus:ring-4 focus:ring-blue-200"  
              >
                <IoCloudDownloadOutline onClick={handleDownload}/>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ScriptModal;
