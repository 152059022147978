import Header from '../Components/Header';
import AcceptModal from '../shared/acceptModal';
import { useLocation } from 'react-router-dom';
const Accept = () => {
  const location = useLocation();
  const { scriptContent } = location.state || {}; // Destructure and get scriptContent from state
  
  return (
    <>
    <Header isEdit={false}/>
    <AcceptModal script={scriptContent}/>
    </>
  )
}

export default Accept